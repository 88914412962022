import {
  errorResult,
  MassCommunication,
  MassCommunicationData,
  MASS_COMMUNICATION_VERSION,
  normalizeMassCommunication,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";

interface AddMassCommunication {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: Omit<MassCommunicationData, "organizationId">;
  }): Promise<Result<boolean | null>>;
}

const addMassCommunication: AddMassCommunication = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new massCommunication id
    const newMassCommunicationRef = doc(collection(db, "mass_communications"));

    // console.log(newMassCommunicationRef);

    const newMassCommunication: MassCommunication = normalizeMassCommunication({
      ...data,
      organizationId,
      id: newMassCommunicationRef.id,
      authorId,
      authorName,
      version: MASS_COMMUNICATION_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newMassCommunicationRef, newMassCommunication);

    // t("MASS_COMMUNICATION_CREATED")
    const successMessage = "MASS_COMMUNICATION_CREATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetMassCommunication {
  ({ db, id }: { db: Firestore; id: string }): Promise<
    Result<MassCommunication | null>
  >;
}

const getMassCommunication: GetMassCommunication = async ({ db, id }) => {
  try {
    const massCommunicationDocRef = doc(db, "mass_communications", id);
    const massCommunicationDocSnapshot = await getDoc(massCommunicationDocRef);
    if (massCommunicationDocSnapshot.exists()) {
      try {
        const data: unknown = massCommunicationDocSnapshot.data();
        const massCommunication: MassCommunication =
          normalizeMassCommunication(data);
        const successMessage = "MASS_COMMUNICATION_FOUD";
        return successResult({
          message: successMessage,
          payload: massCommunication,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "MASS_COMMUNICATION_NOT_FOUD";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface DeleteMassCommunication {
  ({ db, id }: { db: Firestore; id: string }): Promise<Result<boolean | null>>;
}

const deleteMassCommunication: DeleteMassCommunication = async ({ db, id }) => {
  try {
    const docRef = doc(db, "mass_communications", id);

    try {
      await deleteDoc(docRef);
      // t("MASS_COMMUNICATION_DELETED")
      const successMessage = "MASS_COMMUNICATION_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateMassCommunication {
  ({
    db,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<MassCommunicationData>;
  }): Promise<Result<boolean | null>>;
}

const updateMassCommunication: UpdateMassCommunication = async ({
  db,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    if (data.status === "SENT")
      return errorResult({ message: "CANNOT_UPDATE_MASS_COMMUNICATION" });
    const docRef = doc(db, "mass_communications", id);

    const updates: Partial<MassCommunication> = {
      ...data,
      authorId,
      authorName,
      version: MASS_COMMUNICATION_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("MASS_COMMUNICATION_UPDATED")
    const successMessage = "MASS_COMMUNICATION_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization massCommunications listener
interface GetMassCommunicationsListenerCallback {
  (massCommunications: MassCommunication[] | null): void;
}
interface GetMassCommunicationsListener {
  db: Firestore;
  organizationId: string;
  callback: GetMassCommunicationsListenerCallback;
}

const getMassCommunicationsListener = ({
  db,
  organizationId,
  callback,
}: GetMassCommunicationsListener): Unsubscribe => {
  try {
    // console.log("getMassCommunicationsListener: new listener");
    const massCommunicationsQuery = query(
      collection(db, "mass_communications"),
      where("organizationId", "==", organizationId)
    );
    return onSnapshot(massCommunicationsQuery, (querySnapshot) => {
      const massCommunications: MassCommunication[] = [];
      querySnapshot.forEach((doc) => {
        massCommunications.push(normalizeMassCommunication(doc.data()));
      });
      callback(massCommunications);
    });
  } catch (err: any) {
    console.log(err);
    return () => {
      callback(null);
    };
  }
};

// we inject dependencies to improve testability
export const massCommunications = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    addMassCommunication: (
      data: Omit<MassCommunicationData, "organizationId">
    ) =>
      addMassCommunication({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    getMassCommunicationsListener: (
      callback: GetMassCommunicationsListenerCallback
    ) =>
      getMassCommunicationsListener({
        db,
        organizationId,
        callback,
      }),
    getMassCommunication: (id: string) =>
      getMassCommunication({
        db,
        id,
      }),
    updateMassCommunication: (
      id: string,
      data: Partial<MassCommunicationData>
    ) =>
      updateMassCommunication({
        db,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteMassCommunication: (id: string) =>
      deleteMassCommunication({
        db,
        id,
      }),
  };
};
