import { Profile } from "features/Profile";
import { Navigate, Route, Routes as Switch } from "react-router-dom";

import { BNotAuthorized, BNotFound } from "components";
import { Dashboard } from "features/dashboard";
import { RemindersSnapshots } from "features/reminders-snapshots";
import { Tasks } from "features/tasks";
import { AdminRoutes } from "./Configs/admin/AdminRoutes";
import { ClientRoutes } from "./Configs/clients/ClientRoutes";
import { ConsultationsRoutes } from "./Configs/consultations/ConsultationsRoutes";
import { InvoicesRoutes } from "./Configs/invoices/InvoicesRoutes";
import { MessagingCenterRoutes } from "./Configs/messaging-center/MessagingCenterRoutes";
import { ReportingRoutes } from "./Configs/reporting/ReportingRoutes";
import { ScheduleRoutes } from "./Configs/Schedule/ScheduleRoutes";
import { WhiteboardRoutes } from "./Configs/whiteboard/WhiteboardRoutes";

export const RouterConfig = () => {
  // const ability = useAbilityContext();

  return (
    <Switch>
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {ScheduleRoutes}
      {ClientRoutes}
      <Route path="/appointments" element={"appointments"} />
      <Route path="/history" element={"history"} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/reminders" element={<RemindersSnapshots />} />
      <Route path="/tasks" element={<Tasks />} />
      {AdminRoutes()}
      {WhiteboardRoutes}
      {ConsultationsRoutes}
      {InvoicesRoutes}
      {ReportingRoutes}
      {MessagingCenterRoutes}
      <Route path="/not-authorized" element={<BNotAuthorized />} />
      <Route path="*" element={<BNotFound />} />
    </Switch>
  );
};
