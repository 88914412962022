import {
  Box,
  Button,
  Checkbox,
  Code,
  Group,
  Input,
  List,
  Paper,
  Stack,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCircleCheck, IconCircleMinus } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  Client,
  getNotificationByResultType,
  loadingInfoNotification,
  MassCommunication,
  mc_utils,
  normalizeMassCommunication,
  obju,
  Result,
  tu,
} from "beitary-shared";
import {
  BBox,
  BDataTable,
  BDateAndTimeInput,
  MoveBackButton,
} from "components";
import { selectActiveUser } from "features/Authentication/AuthenticationSlice";
import { selectActiveClients } from "features/Clients/Clients.slice";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BDataTableColumn } from "schemas-types";
import {
  rules,
  SMSMassCommunicationFormValues,
} from "./SMSMassCommunicationForm.rules";

type AudienceTableItem = Client & {
  actions?: undefined;
  selected: boolean;
};

export interface SMSMassCommunicationFormProps {
  massCommunication?: MassCommunication;
}

export const SMSMassCommunicationForm = ({
  massCommunication,
}: SMSMassCommunicationFormProps) => {
  const { t } = useTranslation();
  const disabled =
    massCommunication?.status === "SENT" ||
    massCommunication?.status === "PENDING";
  const [submitState, setSubmitState] = useSubmitState();
  const db = useDBServices().clientCommunication.massCommunications;
  const [activeTab, setActiveTab] = useState<string | null>("first");

  const form = useForm<SMSMassCommunicationFormValues>({
    initialValues: massCommunication
      ? {
          ...normalizeMassCommunication(massCommunication),
        }
      : {
          audience: [],
          content: "",
          recipients: [],
          status: "DRAFT",
          title: "",
        },
    validate: rules,
  });

  const org = useAppSelector(selectOrganization);
  const user = useAppSelector(selectActiveUser);
  const clients = useAppSelector(selectActiveClients);

  /**
   * audience selection
   */

  const [tableItems, setTableItems] = useState<AudienceTableItem[]>(
    clients.map((c) => ({
      ...c,
      selected: massCommunication
        ? massCommunication.audience.includes(c.id)
        : false,
    }))
  );

  const selectedClients = tableItems.filter((i) => i.selected);
  const indeterminate =
    selectedClients.length !== 0 &&
    selectedClients.length !== tableItems.length;

  const setSelection = (id: string, selected: boolean) =>
    setTableItems((current) => {
      const items = current.map((i) => {
        if (i.id === id) {
          return { ...i, selected };
        } else {
          return i;
        }
      });
      return items;
    });

  const setSelectedAll = (selected: boolean) =>
    setTableItems((current) => {
      const items = current.map((i) => ({ ...i, selected }));
      return items;
    });

  const columns: BDataTableColumn<AudienceTableItem>[] = [
    {
      accessor: "actions",
      title: (
        <Checkbox
          disabled={disabled}
          indeterminate={indeterminate}
          checked={selectedClients.length === tableItems.length}
          onChange={(event) => setSelectedAll(event.currentTarget.checked)}
        />
      ),
      width: 140,
      render: ({ id, selected }) => (
        <Checkbox
          disabled={disabled}
          checked={selected}
          onChange={(event) => setSelection(id, event.currentTarget.checked)}
        />
      ),
    },
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "email",
      title: <Text>{t("EMAIL")}</Text>,
      sortable: true,
    },
    {
      accessor: "phoneNumber",
      title: <Text>{t("PHONE_NUMBER")}</Text>,
      sortable: true,
    },
  ];
  /**
   *
   */

  const getContentPreviewText = () => {
    const client = user?.displayName ?? "";
    const clinicAddress = [
      org?.address_Line1,
      org?.address_Line2,
      org?.address_postalCode,
      org?.address_city,
      org?.address_stateOrProvince,
    ]
      .filter((i) => i !== undefined && i !== "")
      .join(", ");
    return mc_utils.generateMCNotifText(form.values.content ?? "", {
      client,
      clinicAddress,
      clinicEmail: org?.email ?? "",
      clinicName: org?.name ?? "",
      clinicPhone: org?.phoneNumber ?? "",
    });
  };

  const submit = async (values: SMSMassCommunicationFormValues) => {
    const notificationId = "submit-mass-comm";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("ADD_TEMPLATE")
        // t("UPDATE_TEMPLATE")
        title: t("SAVING_MASS_COMMUNICATION"),
      })
    );
    setSubmitState("pending-response");
    obju.removeUndefined(values);
    let result: Result<boolean | null>;
    if (massCommunication) {
      result = await db.updateMassCommunication(massCommunication.id, values);
    } else {
      result = await db.addMassCommunication({ ...values, messageType: "SMS" });
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  /**
   * can save/send
   */
  const canSaveDraft = !disabled && form.isValid();
  const canSend =
    !disabled &&
    form.isValid() &&
    form.values.recipients &&
    selectedClients.length > 0;

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack spacing="xl">
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            massCommunication ? (
              <Text weight={500}>{t("EDIT_SMS_MASS_COMMUNICATION")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_SMS_MASS_COMMUNICATION")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              required
              label={t("TITLE")}
              {...form.getInputProps("title")}
            />
            <Tabs
              value={activeTab}
              onTabChange={setActiveTab}
              variant="outline"
            >
              <Tabs.List>
                <Tabs.Tab value="first">{t("CONTENT")}</Tabs.Tab>
                <Tabs.Tab value="second">{t("AUDIENCE")}</Tabs.Tab>
                <Tabs.Tab value="third">{t("REVIEW_AND_SEND")}</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="first">
                <Group align="flex-start" position="apart">
                  <Box w="65%">
                    <Input.Wrapper required>
                      <Tabs variant="default" defaultValue="form" mt="sm">
                        <Tabs.List>
                          <Tabs.Tab value="form">{t("EDIT")}</Tabs.Tab>
                          <Tabs.Tab value="preview">{t("PREVIEW")}</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="form" pt="xs">
                          <Textarea
                            disabled={disabled}
                            minRows={6}
                            {...form.getInputProps("content")}
                            onChange={(v) => {
                              form.setFieldValue("content", v.target.value);
                              console.log(v.target.value);
                            }}
                          />
                        </Tabs.Panel>

                        <Tabs.Panel value="preview" pt="xs">
                          <Paper p="xl">
                            <Text>
                              {" "}
                              {HTMLReactParser(getContentPreviewText())}
                            </Text>
                          </Paper>
                        </Tabs.Panel>
                      </Tabs>{" "}
                    </Input.Wrapper>
                  </Box>
                  <Box w="30%" mt="lg">
                    <Text mb="xl" size="sm">
                      {t("SMS_NOTIF_TEMPLATE_CONTENT_EXPLAINER")}
                    </Text>
                    <List size="sm" withPadding>
                      <List.Item>
                        <Code color="cyan">{"{CLIENT}"}</Code> : {t("CLIENT")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{CLINIC_NAME}"}</Code> :{" "}
                        {t("CLINIC_NAME")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{CLINIC_EMAIL}"}</Code> :{" "}
                        {t("CLINIC_EMAIL")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{CLINIC_PHONE}"}</Code> :{" "}
                        {t("CLINIC_PHONE")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{CLINIC_ADDRESS}"}</Code> :{" "}
                        {t("CLINIC_ADDRESS")}
                      </List.Item>
                    </List>
                  </Box>
                </Group>
              </Tabs.Panel>
              <Tabs.Panel value="second">
                <Stack mt="xl">
                  <BDataTable
                    data={tableItems}
                    columns={columns}
                    defaultSortKey="name"
                  />
                </Stack>
              </Tabs.Panel>
              <Tabs.Panel value="third">
                <Stack p="xl">
                  <Paper withBorder p="lg">
                    <Text fw="bold">{t("AUDIENCE")}</Text>
                    <Text>{selectedClients.length}</Text>
                  </Paper>
                  <Paper withBorder p="lg">
                    <Text fw="bold">{t("PREVIEW")}</Text>
                    <Text> {HTMLReactParser(getContentPreviewText())}</Text>
                  </Paper>
                  <BDateAndTimeInput
                    disabled={disabled}
                    clearable
                    label={t("SCHEDULE_ON")}
                    minDateTime={tu.getCurrentDateTime()}
                    {...form.getInputProps("scheduledOn")}
                  />
                </Stack>
              </Tabs.Panel>
            </Tabs>

            <Group position="right" mt="xl">
              <Button.Group>
                <Button
                  disabled={activeTab === "first"}
                  onClick={() =>
                    setActiveTab(activeTab === "second" ? "first" : "second")
                  }
                >
                  {t("PREVIOUS")}
                </Button>
                <Button
                  disabled={activeTab === "third"}
                  onClick={() =>
                    setActiveTab(activeTab === "second" ? "third" : "second")
                  }
                >
                  {t("NEXT")}
                </Button>
              </Button.Group>{" "}
              <Button
                disabled={disabled}
                onClick={() => {
                  canSaveDraft &&
                    form.setValues({
                      status: "DRAFT",
                      audience: selectedClients.map((c) => c.id),
                      recipients: form.values.recipients ?? [],
                    });
                }}
                variant="filled"
                type="submit"
                loading={submitState === "pending-response"}
                color={canSaveDraft ? "green" : "orange"}
                leftIcon={
                  canSaveDraft ? (
                    <IconCircleCheck size={18} />
                  ) : (
                    <IconCircleMinus size={18} />
                  )
                }
              >
                {t("SAVE_DRAFT")}
              </Button>
              {activeTab === "third" && (
                <Button
                  disabled={disabled}
                  onClick={() => {
                    canSend &&
                      form.setValues({
                        status: "PENDING",
                        audience: selectedClients.map((c) => c.id),
                        recipients: [],
                      });
                  }}
                  type="submit"
                  loading={submitState === "pending-response"}
                  color={canSend ? "green" : "orange"}
                  leftIcon={
                    canSend ? (
                      <IconCircleCheck size={18} />
                    ) : (
                      <IconCircleMinus size={18} />
                    )
                  }
                >
                  {form.values.scheduledOn === undefined
                    ? t("SEND")
                    : t("SCHEDULE_SEND")}
                </Button>
              )}
            </Group>
          </Stack>
        </BBox>
      </Stack>
    </form>
  );
};
