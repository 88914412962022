import {
  errorResult,
  normalizeOrganization,
  normalizeQuota,
  Organization,
  OrganizationData,
  ORGANIZATION_VERSION,
  Quota,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import { Unsubscribe } from "firebase/auth";
import {
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

interface GetOrganization {
  ({ db, id }: { db: Firestore; id: string }): Promise<
    Result<Organization | null>
  >;
}

const getOrganization: GetOrganization = async ({ db, id }) => {
  try {
    const organizationDocRef = doc(db, "organizations", id);
    const organizationDocSnapshot = await getDoc(organizationDocRef);
    if (organizationDocSnapshot.exists()) {
      try {
        const data: unknown = organizationDocSnapshot.data();
        const organization: Organization = normalizeOrganization(data);
        const successMessage = "Organization found!";
        return successResult({
          message: successMessage,
          payload: organization,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "Organization not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetOrganizations {
  ({ db, ids }: { db: Firestore; ids: string[] }): Promise<
    Result<Organization[] | null>
  >;
}

const getOrganizations: GetOrganizations = async ({ db, ids }) => {
  try {
    const refs = ids.map((id) => doc(db, "organizations", id));

    const snapshots = await Promise.all(refs.map(async (r) => await getDoc(r)));

    const orgs: Organization[] = [];

    snapshots.forEach((s) => {
      try {
        orgs.push(normalizeOrganization(s.data()));
      } catch (error) {
        console.log("could not normalize org:");
        console.log(error);
      }
    });

    const successMessage = "DONE";
    return successResult({
      message: successMessage,
      payload: orgs,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditOrganization {
  ({
    db,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: OrganizationData;
  }): Promise<Result<string | null>>;
}

const editOrganization: EditOrganization = async ({
  db,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const updates: Partial<Organization> = {
      ...data,
      authorId,
      authorName,
      version: ORGANIZATION_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    const organizationRef = doc(db, "organizations", id);

    const docPath = organizationRef.path;

    await updateDoc(organizationRef, updates);

    const successMessage = "Organization updated!";
    // console.log(successMessage, updatedOrganization);
    return successResult({
      message: successMessage,
      payload: docPath,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface UpdateOrganizationPartial {
  ({
    db,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<OrganizationData>;
  }): Promise<Result<boolean | null>>;
}

const updateOrganizationPartial: UpdateOrganizationPartial = async ({
  db,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const updates: Partial<Organization> = {
      ...data,
      authorId,
      authorName,
      version: ORGANIZATION_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    const organizationRef = doc(db, "organizations", id);

    await updateDoc(organizationRef, updates);

    const successMessage = "Organization updated!";
    // console.log(successMessage, updatedOrganization);
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization bSettings listener
interface GetQuotaListenerCallback {
  (bQuota: Quota): void;
}
interface GetQuotaListener {
  db: Firestore;
  id: string;
  callback: GetQuotaListenerCallback;
}

const getQuotaListener = ({
  db,
  id,
  callback,
}: GetQuotaListener): Unsubscribe => {
  try {
    const bQuotaRef = doc(db, "quotas", id);

    return onSnapshot(bQuotaRef, (querySnapshot) => {
      try {
        callback(normalizeQuota(querySnapshot.data()));
      } catch (error) {
        console.log(error);
      }
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const organization = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getOrganization: (id: string) =>
      getOrganization({
        db,
        id,
      }),
    getOrganizations: (ids: string[]) => getOrganizations({ db, ids }),
    getCurrentOrganization: () =>
      getOrganization({
        db,
        id: organizationId,
      }),
    editOrganization: (id: string, data: OrganizationData) =>
      editOrganization({
        db,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    updateOrganizationPartial: (id: string, data: Partial<OrganizationData>) =>
      updateOrganizationPartial({
        db,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    getQuotaListener: (callback: GetQuotaListenerCallback) =>
      getQuotaListener({
        db,
        id: organizationId,
        callback,
      }),
  };
};
