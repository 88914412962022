import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

// t("EMAIL")
// t("SMS")
// t("DRAFT")
// t("SCHEDULED")
// t("PENDING")
// t("SENDING")
// t("SENT")
export const massCommunicationMessageType = z.enum(["EMAIL", "SMS"]);
export const massCommunicationStatus = z.enum([
  "DRAFT",
  "PENDING",
  "SENDING",
  "SENT",
]);

const massCommunicationDataV0Schema = z.object({
  status: massCommunicationStatus,
  title: z.string(),
  messageType: massCommunicationMessageType,
  subject: z.string().optional(),
  content: z.string(),
  recipients: z.array(z.string()),
  audience: z.array(z.string()),
  sentOn: z.number().int().positive().optional(),
  scheduledOn: z.number().int().positive().optional(),
  organizationId: z.string(),
});

type MassCommunicationDataV0 = z.infer<typeof massCommunicationDataV0Schema>;

const massCommunicationV0Schema = baseRecordSchema.merge(
  massCommunicationDataV0Schema
);

type MassCommunicationV0 = z.infer<typeof massCommunicationV0Schema>;

const normalizeMassCommunicationV0 = (data: any): MassCommunication => {
  try {
    const massCommunicationV0: MassCommunicationV0 =
      massCommunicationV0Schema.parse(data);

    const { ...rest } = massCommunicationV0;

    // here it's same object cause current v is 0
    const massCommunication: MassCommunication = {
      ...rest,
    };
    return massCommunication;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const MASS_COMMUNICATION_VERSION = "0";

export type MassCommunicationData = MassCommunicationDataV0;

export type MassCommunication = BaseRecord & MassCommunicationData;

export const massCommunicationDataSchema = massCommunicationDataV0Schema;

export const massCommunicationSchema = massCommunicationV0Schema;

export const normalizeMassCommunication = (data: any): MassCommunication => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeMassCommunicationV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
