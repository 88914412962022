import { zodResolver } from "@mantine/form";
import { massCommunicationDataSchema } from "beitary-shared";
import { z } from "zod";

const sMSMassCommunicationFormSchema = massCommunicationDataSchema.omit({
  // status: true,
  // recipients: true,
  // audience: true,
  organizationId: true,
  messageType: true,
});

export type SMSMassCommunicationFormValues = z.infer<
  typeof sMSMassCommunicationFormSchema
>;

export const rules = zodResolver(sMSMassCommunicationFormSchema);
