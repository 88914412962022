import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconDeviceMobileMessage, IconMail } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { MassCommunication, OrgPermissions } from "beitary-shared";
import { BBox, MoveBackButton } from "components";
import { selectQuota } from "features/Organization/Organization.slice";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MassCommunicationTable } from "./components";

interface ViewProps {
  massCommunications: undefined | null | MassCommunication[];
}
export const View = ({ massCommunications }: ViewProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const quota = useAppSelector(selectQuota);

  const smsComms = (massCommunications ?? []).filter(
    (c) => c.messageType === "SMS"
  );
  const pendingSmsComms = smsComms.filter((c) => c.status === "PENDING");
  const smsPending = pendingSmsComms.reduce(
    (acc: number, mc: MassCommunication) => acc + mc.audience.length,
    0
  );
  const sentSmsComms = smsComms.filter((c) => c.status === "SENT");
  const smsSent = sentSmsComms.reduce(
    (acc: number, mc: MassCommunication) => acc + mc.audience.length,
    0
  );
  const smsAvailableCredits =
    (quota?.sms.base ?? 0) -
    (quota?.sms.consumed ?? 0) -
    (quota?.sms.consuming ?? 0);

  const emailComms = (massCommunications ?? []).filter(
    (c) => c.messageType === "EMAIL"
  );
  const pendingEmailComms = emailComms.filter((c) => c.status === "PENDING");
  const emailPending = pendingEmailComms.reduce(
    (acc: number, mc: MassCommunication) => acc + mc.audience.length,
    0
  );
  const sentEmailComms = emailComms.filter((c) => c.status === "SENT");
  const emailSent = sentEmailComms.reduce(
    (acc: number, mc: MassCommunication) => acc + mc.audience.length,
    0
  );
  const emailAvailableCredits =
    (quota?.email.base ?? 0) -
    (quota?.email.consumed ?? 0) -
    (quota?.email.consuming ?? 0);

  return (
    <Container fluid>
      <Stack mt="xl">
        <Group mb="md">
          <MoveBackButton />
        </Group>
        <Group position="apart">
          <Title mt="xl" weight={500} order={3}>
            {t("MASS_COMMUNICATION")}
          </Title>
          <Paper withBorder radius="xs" px="lg" py="sm">
            <Group>
              <Grid gutter={48} align="center" justify="center">
                <Grid.Col span={2}>
                  <ThemeIcon variant="light">
                    <IconDeviceMobileMessage />
                  </ThemeIcon>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>{t("SENT")}</Text>
                  <Text>{smsSent}</Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>{t("AVAILABLE")}</Text>
                  <Text>{smsAvailableCredits}</Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>{t("SCHEDULED")}</Text>
                  <Text
                    c={smsPending > smsAvailableCredits ? "red.4" : "green.4"}
                  >
                    {smsPending}
                  </Text>
                </Grid.Col>
              </Grid>
              <Divider orientation="vertical" mx="md" />
              <Grid gutter={48} align="center" justify="center">
                <Grid.Col span={2}>
                  <ThemeIcon variant="light">
                    <IconMail />
                  </ThemeIcon>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>{t("SENT")}</Text>
                  <Text>{emailSent}</Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>{t("AVAILABLE")}</Text>
                  <Text>{emailAvailableCredits}</Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>{t("SCHEDULED")}</Text>
                  <Text
                    c={
                      emailPending > emailAvailableCredits ? "red.4" : "green.4"
                    }
                  >
                    {emailPending}
                  </Text>
                </Grid.Col>
              </Grid>
            </Group>
          </Paper>
        </Group>

        <BBox
          header={
            <Group position="right">
              <BCan I="do" a={OrgPermissions.MESSAGING_ACCESS}>
                <Button
                  onClick={() => navigate("new-sms")}
                  leftIcon={<IconDeviceMobileMessage />}
                >
                  {t("NEW_SMS_MASS_COMMUNICATION")}
                </Button>
              </BCan>
              <BCan I="do" a={OrgPermissions.MESSAGING_ACCESS}>
                <Button
                  onClick={() => navigate("new-email")}
                  leftIcon={<IconMail />}
                >
                  {t("NEW_EMAIL_MASS_COMMUNICATION")}
                </Button>
              </BCan>
            </Group>
          }
        >
          <Box p="xl">
            <MassCommunicationTable massCommunications={massCommunications} />
          </Box>
        </BBox>
      </Stack>
    </Container>
  );
};
