import { MassCommunication } from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { View } from "./MassCommunication.view";

export const Container = () => {
  const [massCommunications, setMassCommunications] = useState<
    undefined | null | MassCommunication[]
  >();
  const db = useDBServices().clientCommunication.massCommunications;

  useEffect(() => {
    const listener = db.getMassCommunicationsListener(setMassCommunications);
    return () => {
      listener();
    };
  }, [db]);
  return <View massCommunications={massCommunications} />;
};
