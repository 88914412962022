import { zodResolver } from "@mantine/form";
import { massCommunicationDataSchema } from "beitary-shared";
import { z } from "zod";

const emailMassCommunicationFormSchema = massCommunicationDataSchema.omit({
  // status: true,
  // recipients: true,
  // audience: true,
  organizationId: true,
  messageType: true,
});

export type EmailMassCommunicationFormValues = z.infer<
  typeof emailMassCommunicationFormSchema
>;

export const rules = zodResolver(emailMassCommunicationFormSchema);
