import { Grid, Group, Stack, Switch, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { OrgPermissions } from "beitary-shared";
import { BBox, EditButton } from "components";
import { selectOrganization } from "features/Organization/Organization.slice";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface SettingsProps {}

export const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const org = useAppSelector(selectOrganization);

  if (!org) return <Text>{t("ORGANIZATION_NOT_FOUND")}</Text>;

  const { advancedSettings } = org;

  const {
    sendEmailAptNotif,
    sendEmailNotifOnClientCreate,
    sendEmailNotifOnPatientCreate,
    sendEmailReminderNotif,
    sendSMSAptNotif,
    sendSMSNotifOnClientCreate,
    sendSMSNotifOnPatientCreate,
    sendSMSReminderNotif,
    enableMassCommunication,
  } = advancedSettings;

  return (
    <Stack>
      <BBox
        header={
          <Group>
            <Text weight={500}>{t("SETTINGS")}</Text>
          </Group>
        }
      >
        <Grid gutter="xs">
          <Grid.Col md={12} lg={6} px="xl">
            <Stack p="xl" spacing="xl">
              <Text weight={500}>{t("APPOINTMENT_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text>{t("SEND_APT_EMAIL_NOTIFICATION")}</Text>
                <Switch checked={sendEmailAptNotif ?? false} readOnly />
              </Group>
              <Group position="apart">
                <Text>{t("SEND_APT_SMS_NOTIFICATION")}</Text>
                <Switch checked={sendSMSAptNotif ?? false} readOnly />
              </Group>
            </Stack>
          </Grid.Col>
          <Grid.Col md={12} lg={6} px="xl">
            <Stack p="xl" spacing="xl">
              <Text weight={500}>{t("REMINDERS_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text>{t("SEND_REMINDER_EMAIL_NOTIFICATION")}</Text>
                <Switch checked={sendEmailReminderNotif ?? false} readOnly />
              </Group>
              <Group position="apart">
                <Text>{t("SEND_REMINDER_SMS_NOTIFICATION")}</Text>
                <Switch checked={sendSMSReminderNotif ?? false} readOnly />
              </Group>
            </Stack>
          </Grid.Col>
          <Grid.Col md={12} lg={6} px="xl">
            <Stack p="xl" spacing="xl">
              <Text weight={500}>{t("CLIENT_CREATED_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text>{t("SEND_EMAIL_NOTIFICATION_ON_CLIENT_CREATE")}</Text>
                <Switch
                  checked={sendEmailNotifOnClientCreate ?? false}
                  readOnly
                />
              </Group>
              <Group position="apart">
                <Text>{t("SEND_SMS_NOTIFICATION_ON_CLIENT_CREATE")}</Text>
                <Switch
                  checked={sendSMSNotifOnClientCreate ?? false}
                  readOnly
                />
              </Group>
            </Stack>
          </Grid.Col>

          <Grid.Col md={12} lg={6} px="xl">
            <Stack p="xl" spacing="xl">
              <Text weight={500}>{t("PATIENT_CREATED_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text>{t("SEND_EMAIL_NOTIFICATION_ON_PATIENT_CREATE")}</Text>
                <Switch
                  checked={sendEmailNotifOnPatientCreate ?? false}
                  readOnly
                />
              </Group>
              <Group position="apart">
                <Text>{t("SEND_SMS_NOTIFICATION_ON_PATIENT_CREATE")}</Text>
                <Switch
                  checked={sendSMSNotifOnPatientCreate ?? false}
                  readOnly
                />
              </Group>
            </Stack>
          </Grid.Col>

          <Grid.Col md={12} lg={6} px="xl">
            <Stack p="xl" spacing="xl">
              <Text weight={500}>{t("MESSAGING_CENTER")}</Text>
              <Group position="apart">
                <Text>{t("ENABLE_MASS_COMMUNICATION")}</Text>
                <Switch checked={enableMassCommunication ?? false} readOnly />
              </Group>
            </Stack>
          </Grid.Col>
        </Grid>
      </BBox>
      <Group position="right">
        <BCan I="do" a={OrgPermissions.CLINIC_SETTINGS_CUD}>
          <EditButton f={() => navigate("edit")} />
        </BCan>
      </Group>
    </Stack>
  );
};
