import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import {
  emailAppointmentNotificationTemplates,
  smsAppointmentNotificationTemplates,
} from "./appointment-notification-templates";
import { communicationHistory } from "./communication-history/communication-history";
import { massCommunications } from "./mass-comunication";
import {
  emailReminderNotificationTemplates,
  smsReminderNotificationTemplates,
} from "./reminders";
import { reminders } from "./reminders/reminders";
import { reminderSnapshots } from "./reminders/reminders-snapshots";

export const clientCommunication = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    emailAppointmentNotificationsTemplates:
      emailAppointmentNotificationTemplates(
        db,
        organizationId,
        authorId,
        authorName,
        source
      ),
    smsAppointmentNotificationsTemplates: smsAppointmentNotificationTemplates(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    reminders: reminders(db, organizationId, authorId, authorName, source),
    reminderSnapshots: reminderSnapshots(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    smsReminderNotificationTemplates: smsReminderNotificationTemplates(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    emailReminderNotificationTemplates: emailReminderNotificationTemplates(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    communicationHistory: communicationHistory(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    massCommunications: massCommunications(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
  };
};
