import { Text } from "@mantine/core";
import { MassCommunication } from "beitary-shared";
import { BLoader } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SMSMassCommunicationForm } from "../SMSMassCommunicationForm";

export interface EditSMSMassCommunicationProps {}

export const EditSMSMassCommunication = () => {
  const { t } = useTranslation();

  const [massCommunication, setMassCommunication] = useState<
    MassCommunication | null | undefined
  >();

  const { massCommunicationId } = useParams();

  const db = useDBServices().clientCommunication.massCommunications;

  useEffect(() => {
    const getComm = async () => {
      if (!massCommunicationId) return;
      const r = await db.getMassCommunication(massCommunicationId);
      setMassCommunication(r.payload);
    };

    getComm();
  }, [db, massCommunicationId]);

  if (massCommunication === undefined) {
    return <BLoader />;
  }
  if (massCommunication === null) {
    return <Text>{t("ERROR")}</Text>;
  }

  return <SMSMassCommunicationForm massCommunication={massCommunication} />;
};
