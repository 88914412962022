import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Organization, Quota } from "beitary-shared";

interface OrganizationsState {
  organization: Organization | null;
  quota: Quota | null | undefined;
}

const key = "organization";

const initialState: OrganizationsState = {
  organization: null,
  quota: undefined,
};

export const organizationSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization | null>) => {
      state.organization = action.payload;
    },
    setQuota: (state, action: PayloadAction<Quota | undefined>) => {
      state.quota = action.payload;
    },
  },
});

export const { setOrganization, setQuota } = organizationSlice.actions;

export const selectOrganization = (state: RootState) =>
  state.organization.organization;
export const selectQuota = (state: RootState) => state.organization.quota;

export const organizationReducer = organizationSlice.reducer;
