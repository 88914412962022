import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { MassCommunication } from "features/messaging-center/mass-communication";
import {
  AddEmailMassCommunication,
  AddSMSMassCommunication,
  EditEmailMassCommunication,
  EditSMSMassCommunication,
} from "features/messaging-center/mass-communication/components";
import { Outlet, Route } from "react-router-dom";

export const MassCommunicationRoutes = (
  <Route path="mass-communication" element={<Outlet />}>
    <Route index element={<MassCommunication />} />
    {/* <Route path=":clientId" element={<Client />} /> */}

    <Route
      path="new-sms"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
          element={<AddSMSMassCommunication />}
        />
      }
    />
    <Route
      path="edit-sms/:massCommunicationId"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
          element={<EditSMSMassCommunication />}
        />
      }
    />
    <Route
      path="new-email"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
          element={<AddEmailMassCommunication />}
        />
      }
    />
    <Route
      path="edit-email/:massCommunicationId"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
          element={<EditEmailMassCommunication />}
        />
      }
    />
  </Route>
);
