export type MCNotifTempalteData = {
  client: string;
  clinicName: string;
  clinicEmail: string;
  clinicPhone: string;
  clinicAddress: string;
  clinicLogoURL?: string;
};

const generateMCNotifHTML = (str: string, data: MCNotifTempalteData) => {
  const newStr = str
    .replace(new RegExp("{CLIENT}", "g"), data.client)
    .replace(new RegExp("{CLINIC_NAME}", "g"), data.clinicName)
    .replace(new RegExp("{CLINIC_EMAIL}", "g"), data.clinicEmail)
    .replace(new RegExp("{CLINIC_PHONE}", "g"), data.clinicPhone)
    .replace(new RegExp("{CLINIC_ADDRESS}", "g"), data.clinicAddress)
    .replace(
      new RegExp("{CLINIC_LOGO}", "g"),
      `<img
        src="${data.clinicLogoURL ?? ""}"
        height="60px"
        width="auto"
        max-width: 100%;
        alt="logo"
        border="0"
      />`
    );

  return newStr;
};

const generateMCNotifText = (str: string, data: MCNotifTempalteData) => {
  const newStr = str
    .replace(new RegExp("{CLIENT}", "g"), data.client)
    .replace(new RegExp("{CLINIC_NAME}", "g"), data.clinicName)
    .replace(new RegExp("{CLINIC_EMAIL}", "g"), data.clinicEmail)
    .replace(new RegExp("{CLINIC_PHONE}", "g"), data.clinicPhone)
    .replace(new RegExp("{CLINIC_ADDRESS}", "g"), data.clinicAddress);

  return newStr;
};

export const mc_utils = {
  generateMCNotifText,
  generateMCNotifHTML,
};
