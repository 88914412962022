import { useAppDispatch } from "app/hooks";
import {
  Appointment,
  AppointmentRequest,
  AppointmentType,
  BoardingResource,
  Bundle,
  Category,
  ClinicLocation,
  Consultation,
  Diagnosis,
  EmailAppointmentNotificationTemplate,
  EmailReminderNotificationTemplate,
  ImagingDiagnosticItem,
  ImagingIntegrationProduct,
  InventoryItem,
  InventoryItemLocation,
  InventoryItemSupplier,
  Member,
  MemberRole,
  PhysicalExamTemplate,
  Product,
  Reminder,
  ServiceProvider,
  SMSAppointmentNotificationTemplate,
  SMSReminderNotificationTemplate,
  Task,
  tu,
} from "beitary-shared";
import {
  setBundles,
  setImagingIntegrationProducts,
  setProductCategories,
  setProducts,
} from "features/admin/catalog/Catalog.slice";
import {
  setEmailAppointmentNotificationTemplates,
  setEmailReminderNotificationTemplates,
  setReminders,
  setSMSAppointmentNotificationTemplates,
  setSMSReminderNotificationTemplates,
} from "features/admin/client-communication/ClientCommunication.slice";
import {
  setClinicAppSettings,
  setClinicLocations,
} from "features/admin/clinic-settings/ClinicSettings.slice";
import { setDiagnoses } from "features/admin/diagnoses/Diagnoses.slice";
import {
  setInventoryItemLocations,
  setInventoryItems,
  setInventoryItemSuppliers,
} from "features/admin/inventory/Inventory.slice";
import {
  setMemberRoles,
  setMembers,
} from "features/admin/members/Members.slice";
import {
  setAppointmentTypes,
  setBoardingResources,
  setServiceProviders,
} from "features/admin/schedule-settings/ScheduleSettings.slice";
import { setPhysicalExamTemplates } from "features/admin/templates/Templates.slice";
import { setActiveMember } from "features/Authentication/AuthenticationSlice";
import {
  ClientsMap,
  PatientsMap,
  setClients,
  setPatients,
} from "features/Clients/Clients.slice";
import { setCurrentConsultations } from "features/consultations/Consultations.slice";
import { setImagingDiagnosticItems } from "features/diagnostics/Diagnostics.slice";
import {
  setOrganization,
  setQuota,
} from "features/Organization/Organization.slice";
import {
  setAppointmenRequests,
  setAppointments,
} from "features/schedule/appointments/Appointments.slice";
import { setCurrentDayTasks } from "features/tasks/tasks.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { ReactNode, useEffect } from "react";

interface AppWrapperProps {
  children: ReactNode;
}

// use this for all app-level listeners... Needs user to be authenticated
// TODO might need a wrapper for each api? to avoid refetching all if only one changed??
export const AppWrapper = ({ children }: AppWrapperProps) => {
  const dispatch = useAppDispatch();

  const db = useDBServices();

  useEffect(() => {
    const fetchOrg = async () => {
      const organization =
        await db.organizationDBService.getCurrentOrganization();
      dispatch(setOrganization(organization.payload));
      dispatch(setClinicAppSettings(organization.payload));
    };
    fetchOrg();

    const fetchMember = async () => {
      const accountResult =
        await db.organizationMembersDBService.members.getCurrentMember();
      dispatch(setActiveMember(accountResult.payload));
    };
    fetchMember();

    const getQuotaListenerUnsubscribe =
      db.organizationDBService.getQuotaListener((quota) => {
        dispatch(setQuota(quota));
      });

    const getConsultationsListenerUnsubscribe =
      db.consultationsDBService.consultations.getConsultationsListener(
        (items: Consultation[]) => {
          dispatch(setCurrentConsultations(items));
        }
      );

    const getDiagnosesListenerUnsubscribe =
      db.diagnosesDBService.getDiagnosesListener((items: Diagnosis[]) => {
        dispatch(setDiagnoses(items));
      });

    const getAppointmentTypesListenerListenerUnsubscribe =
      db.scheduleSettingsDBService.appointmentTypes.getAppointmentTypesListener(
        (items: AppointmentType[]) => {
          dispatch(setAppointmentTypes(items));
        }
      );

    const getEmailAppointmentNotificationTemplatesListenerUnsubscribe =
      db.clientCommunication.emailAppointmentNotificationsTemplates.getEmailAppointmentNotificationTemplatesListener(
        (items: EmailAppointmentNotificationTemplate[]) => {
          dispatch(setEmailAppointmentNotificationTemplates(items));
        }
      );

    const getSMSAppointmentNotificationTemplatesListenerUnsubscribe =
      db.clientCommunication.smsAppointmentNotificationsTemplates.getSMSAppointmentNotificationTemplatesListener(
        (items: SMSAppointmentNotificationTemplate[]) => {
          dispatch(setSMSAppointmentNotificationTemplates(items));
        }
      );

    const getEmailReminderNotificationTemplatesListenerUnsubscribe =
      db.clientCommunication.emailReminderNotificationTemplates.getEmailReminderNotificationTemplatesListener(
        (items: EmailReminderNotificationTemplate[]) => {
          dispatch(setEmailReminderNotificationTemplates(items));
        }
      );

    const getSMSReminderNotificationTemplatesListenerUnsubscribe =
      db.clientCommunication.smsReminderNotificationTemplates.getSMSReminderNotificationTemplatesListener(
        (items: SMSReminderNotificationTemplate[]) => {
          dispatch(setSMSReminderNotificationTemplates(items));
        }
      );

    const getBoardingResourcesListenerListenerUnsubscribe =
      db.scheduleSettingsDBService.boardingResources.getBoardingResourcesListener(
        (items: BoardingResource[]) => {
          dispatch(setBoardingResources(items));
        }
      );

    const getServiceProvidersListenerUnsubscribe =
      db.scheduleSettingsDBService.serviceProviders.getServiceProvidersListener(
        (items: ServiceProvider[]) => {
          dispatch(setServiceProviders(items));
        }
      );

    const getPhysicalExamTemplatesListenerUnsubscribe =
      db.templatesDBService.physicalExamTemplates.getPhysicalExamTemplatesListener(
        (items: PhysicalExamTemplate[]) => {
          dispatch(setPhysicalExamTemplates(items));
        }
      );

    const getImagingIntegrationProductsListenerUnsubscribe =
      db.catalogDBService.imagingIntegrationProducts.getImagingIntegrationProductsListener(
        (items: ImagingIntegrationProduct[]) => {
          dispatch(setImagingIntegrationProducts(items));
        }
      );

    const getProductsListenerUnsubscribe =
      db.catalogDBService.products.getProductsListener((items: Product[]) => {
        dispatch(setProducts(items));
      });

    const getProductCategoriesListenerUnsubscribe =
      db.catalogDBService.productCategories.getProductCategoriesListener(
        (items: Category[]) => {
          dispatch(setProductCategories(items));
        }
      );

    const getBundlesListenerUnsubscribe =
      db.catalogDBService.bundles.getBundlesListener((items: Bundle[]) => {
        dispatch(setBundles(items));
      });

    const getInventoryItemListenerUnsubscribe =
      db.inventoryDBService.inventoryItems.getInventoryItemsListener(
        (items: InventoryItem[]) => {
          dispatch(setInventoryItems(items));
        }
      );

    const getClinicLocationsListenerUnsubscribe =
      db.clinicSettingsDBService.clinicLocations.getClinicLocationsListener(
        (locations: ClinicLocation[]) => {
          dispatch(setClinicLocations(locations));
        }
      );

    const getInventoryItemLocationsListenerUnsubscribe =
      db.inventoryDBService.inventoryItemLocations.getInventoryItemLocationsListener(
        (locations: InventoryItemLocation[]) => {
          dispatch(setInventoryItemLocations(locations));
        }
      );

    const getInventoryItemSuppliersListenerUnsubscribe =
      db.inventoryDBService.inventoryItemSuppliers.getInventoryItemSuppliersListener(
        (suppliers: InventoryItemSupplier[]) => {
          dispatch(setInventoryItemSuppliers(suppliers));
        }
      );

    const getMembersListenerUnsubscribe =
      db.organizationMembersDBService.members.getMembersListener(
        (members: Member[]) => {
          dispatch(setMembers(members));
        }
      );

    const getMemberRolesListenerUnsubscribe =
      db.organizationMembersDBService.memberRoles.getMemberRolesListener(
        (memberRoles: MemberRole[]) => {
          dispatch(setMemberRoles(memberRoles));
        }
      );

    const getClientsListenerUnsubscribe =
      db.clientsDBService.getClientsListener((clients: ClientsMap) => {
        // // console.log(clients);
        dispatch(setClients(clients));
      });

    const getPatientsListenerUnsubscribe =
      db.patientsDBService.getPatientsListener((patients: PatientsMap) => {
        // console.log(patients);
        dispatch(setPatients(patients));
      });

    const getAppointmentsListenerUnsubscribe =
      db.appointmentsDBService.getAppointmentsForWeekListener({
        callback: (appointment: Appointment[]) => {
          dispatch(setAppointments(appointment));
        },
        day: tu.getStartOfDate(tu.getCurrentDate()),
      });

    const getAppointmentsRequestListenerUnsubscribe =
      db.appointmentRequestsDBService.getAppointmentRequestsListener(
        (appointmentRequests: AppointmentRequest[]) => {
          dispatch(setAppointmenRequests(appointmentRequests));
        }
      );

    const getRemindersListenerUnsubscribe =
      db.clientCommunication.reminders.getRemindersListener(
        (reminders: Reminder[]) => {
          dispatch(setReminders(reminders));
        }
      );

    const getTasksForDayListenerUnsubscribe =
      db.tasksDBService.getTasksForDayListener({
        callback: (reminders: Task[]) => {
          dispatch(setCurrentDayTasks(reminders));
        },
        day: tu.getStartOfDate(tu.getCurrentDate()),
      });

    const getImagingDiagnosticItemsListenerUnsubscribe =
      db.imagingDiagnosticItemsDBService.getImagingDiagnosticItemsListener(
        (items: ImagingDiagnosticItem[]) => {
          dispatch(setImagingDiagnosticItems(items));
        }
      );

    return () => {
      getQuotaListenerUnsubscribe();
      getConsultationsListenerUnsubscribe();
      getDiagnosesListenerUnsubscribe();
      getAppointmentTypesListenerListenerUnsubscribe();
      getEmailAppointmentNotificationTemplatesListenerUnsubscribe();
      getSMSAppointmentNotificationTemplatesListenerUnsubscribe();
      getEmailReminderNotificationTemplatesListenerUnsubscribe();
      getSMSReminderNotificationTemplatesListenerUnsubscribe();
      getBoardingResourcesListenerListenerUnsubscribe();
      getServiceProvidersListenerUnsubscribe();
      getPhysicalExamTemplatesListenerUnsubscribe();
      getImagingIntegrationProductsListenerUnsubscribe();
      getProductsListenerUnsubscribe();
      getProductCategoriesListenerUnsubscribe();
      getBundlesListenerUnsubscribe();
      getInventoryItemListenerUnsubscribe();
      getInventoryItemLocationsListenerUnsubscribe();
      getInventoryItemSuppliersListenerUnsubscribe();
      getClinicLocationsListenerUnsubscribe();
      getMembersListenerUnsubscribe();
      getMemberRolesListenerUnsubscribe();
      getClientsListenerUnsubscribe();
      getPatientsListenerUnsubscribe();
      getAppointmentsListenerUnsubscribe();
      getAppointmentsRequestListenerUnsubscribe();
      getRemindersListenerUnsubscribe();
      getTasksForDayListenerUnsubscribe();
      getImagingDiagnosticItemsListenerUnsubscribe();
    };
  }, [dispatch, db]);

  return <>{children}</>;
};
